<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" class="mb-8">
        <v-row>
          <v-col cols="4" lg="4" md="4" sm="12" class="pt-dashboard-total-box">
            <base-material-stats-card color="green lighten-2" icon="mdi-account-check" :title="str['active_clients']"
              :value="activeClients" />
          </v-col>
          <v-col cols="4" lg="4" md="4" sm="12" class="pt-dashboard-total-box">
            <base-material-stats-card color="orange lighten-2" icon="mdi-account-lock" :title="str['suspended_clients']"
              :value="suspendedClients" />
          </v-col>
          <v-col cols="4" lg="4" md="4" sm="12" class="pt-dashboard-total-box">
            <base-material-stats-card color="red lighten-2" icon="mdi-account-cancel" :title="str['blocked_clients']"
              :value="blockedClients" />
          </v-col>
          <v-col v-if="dataChart" cols="12">
            <base-material-chart-card :data="dataChart" :options="chartOptions" color="blue-grey darken-3" hover-reveal
              type="Line" :shadow="true">
              <h4 class="card-title font-weight-light mt-2 ml-2">
                Progresso de clientes - Mensal
              </h4>
            </base-material-chart-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '../../services/Api'
import Utils from '../../services/Utils'

export default {
  name: 'Dashboard',
  data() {
    return {
      str: window.strings,
      clients: null,
      activeClients: '...',
      suspendedClients: '...',
      blockedClients: '...',
      chartOptions: {
        lineSmooth: this.$chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: 50,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        plugins: [
          this.$chartist.plugins.tooltip({
            appendToBody: true,
          })
        ],
      },
      dataChart: null,
      statusMap: {
        active: 0,
        suspended: 1,
        blocked: 2,
      },
    }
  },
  beforeMount: function () {
    this.getTotalActiveClients()
  },
  methods: {
    getTotalActiveClients: function () {
      const self = this
      this.$isLoading(true)

      // Função reutilizável para buscar clientes com base no ID
      const fetchPtClient = (id) => {
        return new Promise((resolve) => {
          Api.getClient(id, {
            id: id,
            fields: ['id', 'name', 'register_date', 'status']
          }, (response) => {
            self.$isLoading(false)
            if (response.success) {
              resolve(response.data[0])
            } else {
              resolve(null) // Retorna null se a requisição falhar
            }
          })
        })
      }

      // Obter clientes ativos
      Api.getClients(false, {
        fields: ['id', 'name', 'register_date', 'status']
      }, (response) => {
        if (response.success) {
          // Remover PT Admin, TG e FM da contagem
          self.clients = response.data.filter(cl => cl.id !== window.ptDemoId && cl.id !== window.ptTgId && cl.id !== window.ptFmId)

          // Buscar os clientes de ptTg e ptFm
          Promise.all([fetchPtClient(window.ptTgId), fetchPtClient(window.ptFmId)])
            .then(([ptTg, ptFm]) => {
              // Adicionar ptTg e ptFm aos clientes se existirem
              if (ptTg) self.clients.push(ptTg)
              if (ptFm) self.clients.push(ptFm)

              // Atualizar totais e desenhar o gráfico
              self.setTotals()
              self.drawChart()
            })
        } else {
          self.$isLoading(false)
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    setTotals: function () {
      const self = this
      let active = 0
      let suspended = 0
      let blocked = 0
      this.clients.forEach(function (client) {
        switch (client.status) {
          case self.statusMap.active:
            active += 1
            break;
          case self.statusMap.suspended:
            suspended += 1
            break;
          case self.statusMap.blocked:
            blocked += 1
            break;
        }
      })
      this.activeClients = active.toString()
      this.suspendedClients = suspended.toString()
      this.blockedClients = blocked.toString()
    },
    drawChart: function () {
      const dates = {}
      this.clients.forEach(function (client) {
        const split = client.register_date.split('-')
        const date = split[0] + '-' + split[1]
        if (!dates[date]) {
          dates[date] = []
        }
        dates[date].push(client)
      })

      const labels = []
      let data = []
      let max = 0
      for (const date in dates) {
        const value = dates[date].length
        max = value > max ? value : max
        labels.push(date)
        data.push({
          tooltip: date + ': ' + dates[date].map(item => item.name).join(', '),
          label: date,
          value: value
        })
      }
      data = data.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))

      this.dataChart = {
        labels: labels,
        series: [
          data.map(function (item) {
            return {
              meta: item.tooltip,
              value: item.value,
            }
          }),
        ],
      }
      this.chartOptions.high = max + 5
    },
  },
}
</script>
